exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-condiciones-de-uso-jsx": () => import("./../../../src/pages/condiciones-de-uso.jsx" /* webpackChunkName: "component---src-pages-condiciones-de-uso-jsx" */),
  "component---src-pages-contactar-jsx": () => import("./../../../src/pages/contactar.jsx" /* webpackChunkName: "component---src-pages-contactar-jsx" */),
  "component---src-pages-edit-lender-tsx": () => import("./../../../src/pages/edit-lender.tsx" /* webpackChunkName: "component---src-pages-edit-lender-tsx" */),
  "component---src-pages-gpt-tsx": () => import("./../../../src/pages/gpt.tsx" /* webpackChunkName: "component---src-pages-gpt-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preguntas-frecuentes-tsx": () => import("./../../../src/pages/preguntas-frecuentes.tsx" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-tsx" */),
  "component---src-pages-privacidad-jsx": () => import("./../../../src/pages/privacidad.jsx" /* webpackChunkName: "component---src-pages-privacidad-jsx" */),
  "component---src-templates-blog-home-tsx": () => import("./../../../src/templates/blog-home.tsx" /* webpackChunkName: "component---src-templates-blog-home-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-directory-alpha-tsx": () => import("./../../../src/templates/directory-alpha.tsx" /* webpackChunkName: "component---src-templates-directory-alpha-tsx" */),
  "component---src-templates-lender-tsx": () => import("./../../../src/templates/lender.tsx" /* webpackChunkName: "component---src-templates-lender-tsx" */)
}

